import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import PostAddIcon from "@material-ui/icons/PostAdd";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function Chips(props) {
  const classes = useStyles();
  let postedDate = "Date Posted: " + props.postedDate;
  let startDate = "Project Start Date: " + props.startDate;
  let deadline = "Deadline: " + props.deadline;
  let time = "Est. Completion Time: " + props.time;

  return (
    <div className={classes.root}>
      <Chip
        icon={<PostAddIcon />}
        label={postedDate}
        clickable
        color="primary"
        deleteIcon={<DoneIcon />}
      />
      <Chip
        icon={<CheckCircleIcon />}
        label={startDate}
        clickable
        color="primary"
        deleteIcon={<DoneIcon />}
      />
      <Chip
        icon={<AccessAlarmIcon />}
        label={deadline}
        clickable
        color="primary"
        deleteIcon={<DoneIcon />}
      />
      <Chip
        icon={<QueryBuilderIcon />}
        label={time}
        clickable
        color="primary"
        deleteIcon={<DoneIcon />}
      />
    </div>
  );
}
