import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import { Container, Row, Col } from "react-bootstrap";
import CardTimeline from "./CardTimeline";
import CardChips from "./CardChips";
import Paper from "@material-ui/core/Paper";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 285,
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const actions = [
  { icon: <FileCopyIcon />, name: "Edit" },
  { icon: <SaveIcon />, name: "Delete" },
];

export default function NewCard(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let action = <></>;

  switch (props.stage) {
    case 1:
      action = (
        <Button
          variant="contained"
          color="primary"
          endIcon={
            <Badge badgeContent={4} color="secondary">
              <PeopleOutlineIcon />
            </Badge>
          }
        >
          Select Student
        </Button>
      );
      break;
    case 2:
      action = (
        <Button variant="contained" color="primary">
          Contact Student
        </Button>
      );
      break;
    case 3:
      action = (
        <Button variant="contained" color="primary">
          View Hours Request
        </Button>
      );
      break;
    case 4:
      action = (
        <Button variant="contained" color="primary">
          Close
        </Button>
      );
      break;
    default:
    // code block
  }

  return (
    <Paper elevation={3} style={{ marginBottom: "25px", marginTop: "25px" }}>
      <div className={classes.root}>
        <Container style={{ padding: "25px", paddingLeft: "50px" }}>
          <Row>
            <Col>
              <h5>
                <b>{props.title}</b>
              </h5>
              <h8>{props.school}</h8>
            </Col>
            <Col style={{ position: "relative", left: "310px", right: "5px" }}>
              {action}
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <CardTimeline stage={props.stage} />
          </Row>
          <Row>
            <CardChips
              time={props.time}
              deadline={props.deadline}
              startDate={props.startDate}
              postedDate={props.postedDate}
            />
          </Row>
        </Container>
        <Backdrop open={open} style={{ zIndex: "999" }} />

        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          className={classes.speedDial}
          hidden={false}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={handleClose}
            />
          ))}
        </SpeedDial>
      </div>
    </Paper>
  );
}
