import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/utils/layout";
import SEO from "../components/utils/seo";
import NewCard from "../components/cards/NewCard";
import dots from "../images/bgs/dots.svg"

const NotFoundPage = () => (
  <Layout image={dots}>
    <SEO title="404: Not found" />
    <Container>
      <NewCard
        title="Project Title"
        school="Homestead High School"
        stage={3}
        postedDate="Dec 8 2020"
        startDate="Dec 12 2020"
        deadline="Dec 16 2020"
        time="2 hours"
      />
    </Container>
  </Layout>
);

export default NotFoundPage;
